import React, { useEffect, useState } from 'react';
import { loginUser, verifyUser } from '../../../Redux/Actions/action.users';
import Loader from '../../global/Loader/Loader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';

function Login({ users, loginUser, loading, toast,verifyUser }) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails,setUserDetails] = useState({
    user:{},
    isAuthenticated:false,
    error:null
  });

  useEffect(() => {
    const loadingState = loading.length ? true : false;
    setIsLoading(loadingState);
  }, [loading]);



  const loginWithMicrosoft = (event) =>{
    event.preventDefault();
    instance.loginPopup({
      scopes:['user.read']
    }).then((res)=>{
    // localStorage.setItem('user',JSON.stringify(res));
    // navigate('dashboard');
      verifyUser(res.idToken).then(resp=>{
        if(resp.status==200){
          localStorage.setItem('user',JSON.stringify(res));
          navigate('dashboard');
        }
      }).catch(error=>{
        console.log(error);
      });
    }).catch(error=>{
      console.log(error);
    });
  }

  return (
    <>
      {isLoading ? <Loader /> : ''}
      <div className='login-page-background'>
        <div className='login-form p-4 '>
          <form className=''>
            <img src='./images/logo.png' className='my-4 ' alt='logo' />
            <h5 className='fw-semibold'>Sign In</h5>
            <div className='button'>
            <button className='btn btn-primary w-100 mt-2 mb-2 mx-2' onClick={(e)=>{loginWithMicrosoft(e)}}>Sign In with Microsoft</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginUser,
    verifyUser
  }, dispatch);
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    loading: state.loading.loading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);