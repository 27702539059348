import React, { useState,useEffect, useRef } from 'react'
import AddCategory from './AddCategory'
import DataTable from '../Ingestion/DataTable';
import CategoryTable from './CategoryTable';
import Modal from '../global/Modal/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { deleteExistingCategory, getAllCategorieswithPagination, getCategories } from '../../Redux/Actions/action.category';


function Category({toast, categoriesList, deleteExistingCategory, getCategories,pageInfo, getAllCategorieswithPagination}) {

  const navigate = useNavigate();
  const [categoryOptionsArray, setCategoryOptionsArray] = useState(categoriesList);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchCategoriesSelected,setSearchCategoriesSelected] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const categoriesSelectRef = useRef(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [modalTitle,setModalTitle] = useState('');
  const [editCategory,setEditCategory] = useState({});


  const [perPageEntries,setPerPageEntries] = useState(100);
  const [pageDetails,setPageDetails] = useState({
    page: 1,
    perPageSize: perPageEntries,
    sortColumn: 'createdAt',
    sortType: 'desc',
    searchString: ''
  });

  useEffect(()=>{
    const params = {
      page:pageDetails.page,
      perPageSize:pageDetails.perPageSize,
      sortColumn:pageDetails.sortColumn,
      sortType:pageDetails.sortType,
      searchString :pageDetails.searchString
    }
    getAllCategorieswithPagination(params).then(res=>{}).catch(error=>console.log(error))
  },[pageDetails])

  const [pages,setPages] = useState({
    numberOfPages: [...Array(1)],
    pagesDisplayFrom: 1,
    pagesDisplayTo: 1
  });
  useEffect(()=>{
    if(pageInfo){
      const startPage = pageInfo.currentPage%5==0?pages.pagesDisplayFrom:Math.floor((pageInfo.currentPage)/5)*5 + 1;
      const endPage = pageInfo.totalPages > 5 ?  pageInfo.currentPage%5==0? pages.pagesDisplayTo : (Math.floor((pageInfo.currentPage)/5)*5 + 5 > pageInfo.totalPages ? pageInfo.totalPages : Math.floor((pageInfo.currentPage)/5)*5 + 5) : pageInfo.totalPages
      setPages(prev=>({
        ...prev,
        numberOfPages: [...Array(pageInfo.totalPages)],
        pagesDisplayFrom: startPage,
        pagesDisplayTo: endPage
      }))
    }
  },[pageInfo]);

  useEffect(()=>{
    setCategoryOptionsArray(categoriesList);
  },[categoriesList]);

  const handleDropdown = () => {
    setShowOptions(prev=>!prev);
  }

  const handleCategoryOptionSelect = (category) => {
    if(selectedCategories && selectedCategories.find(el=>el == category)) return;
    setSelectedCategories(prev=>([...prev,category]));
    setSearchCategoriesSelected(prev=>([...prev,category.id]))
  }

  const handleSearchCategory = (searchterm) => {
    setCategoryOptionsArray(categoriesList.filter(el=> el.name.toLowerCase().includes(searchterm.toLowerCase())))
  }

  const removeSelectedCategory = (categoryId) => {
    setSelectedCategories(prev=>([...prev.filter(el=>el.id!==categoryId)]));
    setSearchCategoriesSelected(prev=>([...prev.filter(el=>el!==categoryId)]))
  }
  

  
  const toggleModal = (title,category) => {
    const categoryData = {
      id:category?category.id:'',
      name:category?category.name:'',
      description:category?category.description:''
    }
    setEditCategory(categoryData);

    setModalTitle(title);
    setShowEditModal(prev=>!prev);
  }


  const deleteCategory = (category) => {
    const categoriesToDelete = [category.id];
    deleteExistingCategory(categoriesToDelete).then(res=>{
      if(res && res.status == 'logout'){
        localStorage.removeItem('user');
        navigate('/');
      }
      if(res && res.status != 'logout'){
        toast(res.status,res.message);
        getCategories();
        setPageDetails(prev=>({
          ...prev,
          page:1,
          perPageSize:100
        }));
      }
    }).catch(error=>{
      console.log(error);
    });
  }


  const closeModal = () => {
    setShowEditModal(false);
  }

  const optionsRef = useRef(null);
  const handleClickOutside = (e) => {
    if(optionsRef.current && !optionsRef.current.contains(e.target) && e.target.id!=='dropdown-arrow' && e.target.id!=='search-category-input'){
      setShowOptions(false);
    }
  }

  const navigatePage = (newPage) => {
    if(newPage == 0) return;
    if(newPage>pageInfo.totalPages) return;
    if(newPage > pageDetails.page && newPage<=pageInfo.totalPages && newPage>pages.pagesDisplayTo){
      changePagesDisplay('next');
    }
    if(newPage < pageDetails.page && newPage>1 && newPage<pages.pagesDisplayFrom){
      changePagesDisplay('prev');
    }
    setPageDetails((prev)=>({
      ...prev,
      page:newPage
    }))
  }

  const changePagesDisplay = (type) => {
    if(type=='prev'){
      if(pages.pagesDisplayFrom == 1) return ;
        setPages(prev=>({
          ...prev,
          pagesDisplayFrom: pages.pagesDisplayFrom - 5,
          pagesDisplayTo: pages.pagesDisplayFrom - 1
        }))
    }
    if(type=='next'){
      if(pages.pagesDisplayTo == pages.numberOfPages.length) return ;
      setPages(prev=>({
        ...prev,
        pagesDisplayFrom: pages.pagesDisplayFrom + 5,
        pagesDisplayTo: pages.pagesDisplayTo + 5>pages.numberOfPages.length ? pages.numberOfPages.length : pages.pagesDisplayTo + 5
      }))
    }
  }

  const pageChange = (page) => {
    if(pageDetails.page == page) return
    setPageDetails((prev)=>({
      ...prev,
      page: page
    }));
  }

  const updatePageDetails = (e)=>{
    const {name,value} = e.target
    if( !value || value==0 || value>100) return;
    setPageDetails((prev)=>({
      ...prev,
      [name]:value,
      page:1,
    }));
  }

  const updatePageEntries = (value)=>{
    if( !value || value==0 || value>100) return;
    setPerPageEntries(value);
  }
  const handleChange = (searchTerm) => {
    setPageDetails(prev=>({
      ...prev,
      searchString:searchTerm
    }));
  }

  

  return (
    <div className='document-container mx-4' onClick={handleClickOutside}>
      <div className='document-title py-2'>
        <div className='document-heading'>Category</div>
        <div className='search-button ms-auto'>
          <button className='btn btn-primary' onClick={()=>{toggleModal('Add')}}> Add </button>
          <Modal title={`${modalTitle} Category`} showModal={showEditModal} closeModal={() => { closeModal() }}>
            <AddCategory closeModal={closeModal} actionType={modalTitle} editCategory={editCategory} toast={toast}/>
          </Modal>
        </div>
      </div>
      {/* <div className='categories-base-dropdown-container'>
        <div className='categories-dropdown' id='categories'>
            <div className={`categories-dropdown-container col-md-6`} ref={categoriesSelectRef}>
                <div className='input-field'>
                    <div className='search-category-container'>
                        <input type='text' className={`search-category-name`} placeholder='Select Categrories' id='search-category-input' onChange={(e) => { handleSearchCategory(e.target.value) }}/>
                        <i className="fa-solid fa-chevron-down cursor-pointer" onClick={() => { handleDropdown() }} id='dropdown-arrow'></i>
                    </div>
                    <div className='selected-categories-container'>
                        {selectedCategories && selectedCategories.map(category => {
                            return <span key={category.id} className='selected-category py-1'>{category.name} <i className="fa-solid fa-xmark px-1 small" onClick={() => { removeSelectedCategory(category.id) }}></i></span>
                        })}
                    </div>
                </div>
                <div className={`options-container options-position-absolute w-25 ${ showOptions ? '' : 'd-none'}` } ref={optionsRef}>
                    {categoryOptionsArray && categoryOptionsArray.length>0?categoryOptionsArray.map(category => {
                        return <li key={category.id} className={`category-option ${selectedCategories && selectedCategories.find(el => el.id == category.id) ? 'selected' : ''}`}
                              onClick={() => { handleCategoryOptionSelect(category) }}>{category.name}</li>
                    }):
                    <div className={``}>No Options Available</div>
                    }
                </div>
            </div>
        </div>
      </div> */}
      <div className='row'>
        <div className='col-md-6 col-xl-4'>
          <input type='text' className='form-control' onChange={(e)=>handleChange(e.target.value)} placeholder='Search Category'/>
        </div>
      </div>
      <CategoryTable toast={toast} data={categoriesList} toggleModal={toggleModal} deleteCategory={deleteCategory} />
      <div className='document-footer pt-4'>
        <div className='page-numbers-section'>
          <div className={`previous-page px-1 mx-1 ${pages.pagesDisplayFrom==1?'disabled':''}`} onClick={()=>{changePagesDisplay('prev')}}><i className="fas fa-angle-double-left"></i></div>
          <div className={`previous px-1 ${pageDetails.page==1?'disabled':''}`} onClick={()=>{navigatePage(pageDetails.page-1)}} title='Previous Page'><i class="fas fa-angle-left"></i></div>
          <div className={`page-number ${pages.pagesDisplayFrom == 1?'d-none':'continuance-indicator'}`} disabled>...</div>
            {pages.numberOfPages.map((page,index)=>{
              return pages.pagesDisplayFrom-1<index+1 &&  pages.pagesDisplayTo > index && <div key={index+1} className={`page-number px-2 ${pageDetails.page == index+1?'active':''}`} onClick={()=>pageChange(index+1)}>{index + 1}</div>
              })}
            <div className={`page-number ${pages.pagesDisplayTo == pages.numberOfPages.length?'d-none':'continuance-indicator'}`} disabled>...</div>
            <div className={`next px-1 ${pageDetails.page==pages.numberOfPages.length?'disabled':''}`} onClick={()=>{navigatePage(pageDetails.page+1)}} title='Next Page'><i class="fas fa-angle-right"></i></div>
          <div className={`next-page px-1 mx-1 ${pages.pagesDisplayTo==pages.numberOfPages.length?'disabled':''}`} onClick={()=>{changePagesDisplay('next')}}><i className="fas fa-angle-double-right"></i></div>
        </div>
        <div className='page-number-details grow-1'>{`Showing `}
          {categoryOptionsArray?.length?(pageDetails.page-1)*pageDetails.perPageSize+1:0}{ ` to `} 
          {categoryOptionsArray?.length?(pageDetails.page*pageDetails.perPageSize)>(pageInfo?.totalRecords)?(pageInfo?.totalRecords):(pageDetails.page*pageDetails.perPageSize):0}{` of `}
          {Object.keys(pageInfo).length?(pageInfo?.totalRecords):0} entries</div>
        <div className='number-of-pages'>
          Show <input type='text' className='entries-per-page px-1' value={perPageEntries} name='perPageSize' onBlur={(e)=>{updatePageDetails(e)}} onChange={(e)=>{updatePageEntries(e.target.value)}}/>
          entries
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    categoriesList: state.categories.categoriesList,
    pageInfo: state.categories.pageInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getCategories,
    deleteExistingCategory,
    getAllCategorieswithPagination
  },dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)(Category)