import axios from "axios";
import {DOCUMENTS_LIST, SEARCH_OPTIONS, ADD_LOADING, REMOVE_LOADING, SET_CATEGORIES_OPTIONS} from './index';
import { logout } from "./action.users";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
let controller;

export const getAllFiles = (pageDetails) => async (dispatch) => {
    // setLoading(dispatch,'getAllFiles',true);
    const accessToken = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')).idToken:'';
    const url = `${BASE_API_URL}/db/files`;
    if(controller){
        controller.abort();
    }
    controller = new AbortController();
    const params = {
        page: pageDetails.page,
        size: pageDetails.perPageSize,
        sort: `ingestionDatetime, ${pageDetails.sortType}`,
        searchString: pageDetails.searchString,
        categoryIds: pageDetails.searchCategory.join()
    }

    let response ;
    if(!accessToken){
        return response = {
            status:'noToken',
            message:'No Token Provided'
        }
    }
    await axios({
        method:'GET',
        url: url,
        headers:{
        'Authorization': `Bearer ${accessToken}`,
        },
        signal: controller.signal,
        params:params,
        crossOrigin:true
    }).then(async(res)=>{
        setLoading(dispatch,'getAllFiles',false);
        const [sortColumn,sortType] = res.data.page.sort.substring( res.data.page.sort.indexOf("[") + 1, res.data.page.sort.lastIndexOf("]")).split(', ')
        const data = {
            documentList: res.data.data,
            pageInfo:{...res.data.page,page:res.data.page.currentPage,sortType:sortType,sortColumn:sortColumn,searchString:pageDetails.searchString,searchCategory:pageDetails.searchCategory}
        }
        dispatch({
            type: DOCUMENTS_LIST ,
            payload: data
        })
        response={
            status:'success',
            message:'Fetch Successful'
        }
    }).catch(error=>{
        setLoading(dispatch,'getAllFiles',false);
        if(!error){
            console.log('Network Error');
            return response={
                status:'network-error',
                message:'Network error'
            }
            
        }
        if(error && error?.response?.status==401){
            return response={
                status:'logout',
                message:'Session Expired'
            }
            
        }
        if(error){
            response={
                status:'error',
                message:'Error Fetching Files'
            }
        }
    });
    return response;
}


export const getSearchResults = (searchTerm) => async(dispatch) => {
const accessToken = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')).idToken:'';
    let response;
    const url = `${BASE_API_URL}/db/searchFiles`;
    const params = {
        searchString : searchTerm
    }
    response = axios({
        method:'GET',
        url:url,
        headers:{
            'Authorization': `Bearer ${accessToken}`
        },
        params: params,
        crossOrigin: true
    })
    
    return response;
}

export const verifyDuplicateURL = (checkUrl) => async(dispatch) =>{
    const accessToken = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')).idToken:'';
    let response;
    const url = `${BASE_API_URL}/db/verifyWebsiteExistance`
    const config = {
        method: 'GET',
        url: url,
        headers:{
            'Authorization': `Bearer ${accessToken}`
        },
        params:{
            website:checkUrl
        }
    }
    await axios(config).then(res=>{
        console.log(res.data.data.depth)
        return response = {
            status:'success',
            data: res?.data?.data?.depth? res.data.data.depth : []
        }
    }).catch(error=>{
        console.log(error);
        if(!error){
            console.log('Network Error');
            return response={
                status:'network-error',
                message:'Network error'
            }
            
        }
        if(error && error?.response?.status==401){
            return response={
                status:'logout',
                message:'Session Expired'
            }
            
        }
    });
    return response;
}

const setLoading = (dispatch, apiName, loadingState) => {
    loadingState ?
        dispatch({
            type: ADD_LOADING,
            payload: apiName
        }) :
        dispatch({
            type: REMOVE_LOADING,
            payload: apiName
        });
    }