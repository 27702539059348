import { PublicClientApplication } from '@azure/msal-browser';

const PCA = new PublicClientApplication({
    auth:{
      clientId: process.env.REACT_APP_MS_AZURE_ENTRA_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MS_AZURE_ENTRA_TENANT_ID}`,
      redirectUri: `${window.location.origin}/dashboard`
    },
    cache:{
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true
      }
    },
});

export default PCA;