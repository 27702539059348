import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Redirect, Navigate } from 'react-router-dom';
import './App.css';
import Dashboard from './components/Dashboard/dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/User/Login/Login';

import { MsalProvider } from '@azure/msal-react';


function App({ loading, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path='/dashboard' element={<ProtectedRoute Component={Dashboard} />} />
            <Route exact path='/' element={<ProtectedRoute Component={Login} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </MsalProvider>
  );
}



export default App;
