import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loader from './global/Loader/Loader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addToast, removeToast } from './../Redux/Actions/action.toast';
import Toast from './global/Toast/toast';
import Login from './User/Login/Login';
import { verifyUser } from '../Redux/Actions/action.users';

const icons = {
  'success': <i className="fa-solid fa-square-check alert-icon"></i>,
  'error': <i className="fa-solid fa-circle-exclamation alert-icon"></i>,
  'info': <i className="fa-solid fa-circle-info alert-icon"></i>,
  'warning': <i className="fa-solid fa-triangle-exclamation alert-icon"></i>
}


function ProtectedRoute({Component,addToast,removeToast,toastList,loading, verifyUser}) {
    const [isLoading,setLoading] = useState(false);
    const navigate = useNavigate();

// -------------------- User Token Verification --------------------------
    useEffect(()=>{

      const userInfo = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):'';
      if(userInfo=='' && Component!==Login){
        navigate('/');
      }
      if(userInfo!=='' && Component !== Login){
        verifyUser(userInfo.idToken).then(res=>{
          if(res.status==200){
            // Action to perform on success
          }
        }).catch(error=>{
          localStorage.removeItem('user')
          navigate('/');
        })
      }
    },[]);


// -------------- Show Loading ------------------------------------------

    useEffect(()=>{
      if(loading.loading.length>0){ setLoading(true); return; };
      setLoading(false)
    },[loading])


// -------------- Toast functions --------------------------------------

    const addNewToast = (toastType,message) => {
      const newToast = {
        id: Date.now(),
        message: message,
        toastType: toastType,
        icon: icons[toastType]
    }
      addToast(newToast);

      setTimeout(()=>{
        removeToast(newToast.id);
      },5000)
    }
  return (
  <>
    {isLoading && <Loader/>}
    <Toast />
    <Component toast={addNewToast} />
  </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addToast,
    removeToast,
    verifyUser
  }, dispatch);
}

const mapStateToProps = (state) => {
  return {
      toastList: state.toasts.toasts,
      loading:state.loading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)