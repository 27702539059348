import axios from "axios";
import {SET_CUSTOMER_LIST,ADD_LOADING,REMOVE_LOADING} from './index';


const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

export const getAllCustomers = (pageDetails) => (dispatch) => {
    setLoading(dispatch,'get-customers',true)
    const accessToken = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')).idToken:'';
    let response;
    const url = `${BASE_API_URL}/db/customer`;
    const config = {
        method: "GET",
        url: url,
        headers: {
            "access-control-allow-origin": "*",
            // "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Methods": "*",
            "Authorization":`Bearer ${accessToken}`
        },
        params: {
            page:pageDetails.page,
            size:pageDetails.size,
            sort:`${pageDetails.sortColumn},${pageDetails.sortType}`
        },
    }

    if(!accessToken){
        return response = {
            status:'noToken',
            message:'No Token Provided'
        }
    }

    axios(config).then(res=>{
        const [sortColumn,sortType] = res.data.page.sort.substring( res.data.page.sort.indexOf("[") + 1, res.data.page.sort.lastIndexOf("]")).split(', ')
        const data = {
            customerList: res.data.data,
            pageInfo:{...res.data.page,page:res.data.page.currentPage,sortType:sortType,sortColumn:sortColumn}
        }
        dispatch({
            type: SET_CUSTOMER_LIST,
            payload: data
        });
        setLoading(dispatch,'get-customers',false)
    }).catch(error=>{
        setLoading(dispatch,'get-customers',false)
        console.log(error)
    
    })

    return response;
}

export const addCustomerData = (userDetails) => async(dispatch) => {
    setLoading(dispatch,'add-customers',true)
    const accessToken = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')).idToken:'';
    let response;
    const url = `${BASE_API_URL}/db/customer`;
    const config = {
        method: "POST",
        url: url,
        headers: {
            "access-control-allow-origin": "*",
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Methods": "*",
            "Authorization":`Bearer ${accessToken}`
        },
        data: JSON.stringify(userDetails),
    }

    await axios(config).then(res=>{
        setLoading(dispatch,'add-customers',false)
        if(res.status==201){
            return response = {
                status:'success',
                message:`Customer ${userDetails.name} added successfully.`
            }
        }
    }).catch(error=>{
        setLoading(dispatch,'add-customers',false)
        console.log(error);
        if(error && error?.response?.status == 401){
            return response = {
                status:'logout',
                message:'Session Expired'
            }
        }
        if(error && error?.response?.status == 400){
            return response = {
                status: 'error',
                message: error.response.data.messages[0]
            }
        }
    });
    return response;
}

export const updateCustomerData = (userDetails) => async(dispatch) => {
    setLoading(dispatch,'update-customers',true)
    const accessToken = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')).idToken:'';
    let response;
    const url = `${BASE_API_URL}/db/customer`;
    const config = {
        method: "PATCH",
        url: url,
        headers: {
            "access-control-allow-origin": "*",
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Methods": "*",
            "Authorization":`Bearer ${accessToken}`
        },
        data: JSON.stringify(userDetails),
    }
    await axios(config).then(res=>{
        setLoading(dispatch,'update-customers',false)
        if(res.status==200){
            return response = {
                status:'success',
                message:`Customer ${userDetails.name} updated successfully.`
            }
        }
    }).catch(error=>{
        setLoading(dispatch,'update-customers',false)
        console.log(error);
        if(error && error?.response?.status == 401){
            return response = {
                status:'logout',
                message:'Session Expired'
            }
        }
        if(error && error?.response?.status == 400){
            return response = {
                status: 'error',
                message: error.response.data.messages[0]
            }
        }
    });
    return response;
}


const setLoading = (dispatch, apiName, loadingState) => {
    loadingState ?
        dispatch({
            type: ADD_LOADING,
            payload: apiName
        }) :
        dispatch({
            type: REMOVE_LOADING,
            payload: apiName
        });
    }