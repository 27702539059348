import React,{useState,useEffect,useRef} from 'react';
import DataTable from './DataTable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllFiles, getSearchResults } from '../../Redux/Actions/action.document';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../Redux/Actions/action.users';


function Documents({ title, documents , getAllFiles, pageInfo, getSearchResults, toast, logout, categoriesOptions }) {
  const navigate = useNavigate();
  const [perPageEntries,setPerPageEntries] = useState(100);
  const [pageDetails,setPageDetails] = useState({
    page: 1,
    perPageSize: perPageEntries,
    sortColumn: 'ingestionDatetime',
    sortType: 'desc',
    searchString:'',
    searchCategory: []
  });

  const [pages,setPages] = useState({
    numberOfPages: [...Array(1)],
    pagesDisplayFrom: 1,
    pagesDisplayTo: 1
  });

  const [searchTerm,setSearchTerm] = useState('');
  const searchRef = useRef(null);
  useEffect(()=>{
      const params = {
        page: pageDetails.page,
        perPageSize: pageDetails.perPageSize,
        sortColumn: 'ingestionDatetime',
        sortType: Object.keys(pageInfo).length?pageInfo.sortType:'desc',
        searchString:pageDetails.searchString,
        searchCategory:pageDetails.searchCategory,
      }
      getAllFiles(params).then(res=>{
        if(res && res.status == 'logout'){
          localStorage.removeItem('user')
          navigate('/');
        }
      })
  },[pageDetails])


  useEffect(()=>{
    if(pageInfo){
      const startPage = pageInfo.currentPage%5==0?pages.pagesDisplayFrom:Math.floor((pageInfo.currentPage)/5)*5 + 1;
      const endPage = pageInfo.totalPages > 5 ?  pageInfo.currentPage%5==0? pages.pagesDisplayTo : (Math.floor((pageInfo.currentPage)/5)*5 + 5 > pageInfo.totalPages ? pageInfo.totalPages : Math.floor((pageInfo.currentPage)/5)*5 + 5) : pageInfo.totalPages
      setPages(prev=>({
        ...prev,
        numberOfPages: [...Array(pageInfo.totalPages)],
        pagesDisplayFrom: startPage,
        pagesDisplayTo: endPage
      }))
    }
  },[pageInfo]);

  const pageChange = (page) => {
    if(pageDetails.page == page) return
    setPageDetails((prev)=>({
      ...prev,
      page: page
    }));
  }

  const navigatePage = (newPage) => {
    if(newPage == 0) return;
    if(newPage>pageInfo.totalPages) return;
    if(newPage > pageDetails.page && newPage<=pageInfo.totalPages && newPage>pages.pagesDisplayTo){
      changePagesDisplay('next');
    }
    if(newPage < pageDetails.page && newPage>1 && newPage<pages.pagesDisplayFrom){
      changePagesDisplay('prev');
    }
    setPageDetails((prev)=>({
      ...prev,
      page:newPage
    }))
  }

  const handleChange = (searchTermValue) => {
    setSearchTerm(searchTermValue);
    const pageDetailsValues = {
      ...pageDetails,
      page:1,
      searchString:searchTermValue,
    }
    setPageDetails(pageDetailsValues);
  }

  const resetDocumentList = () => {
    const params = {
      page: 1,
      perPageSize: 100,
      sortColumn: 'ingestionDatetime',
      sortType:'desc',
      searchString : '',
      parameterChanged: '',
      searchCategory: []
    }
    setSelectedCategories([]);
    setPageDetails(params);
    setPerPageEntries(100);
    setSearchTerm('');
  }

  const updatePageDetails = (e)=>{
    const {name,value} = e.target
    if( !value || value==0 || value>100) return;
    setPageDetails((prev)=>({
      ...prev,
      [name]:value,
      page:1,
    }));
  }

  const updatePageEntries = (value)=>{
    if( !value || value==0 || value>100) return;
    setPerPageEntries(value);
  }

  const changePagesDisplay = (type) => {
    if(type=='prev'){
      if(pages.pagesDisplayFrom == 1) return ;
        setPages(prev=>({
          ...prev,
          pagesDisplayFrom: pages.pagesDisplayFrom - 5,
          pagesDisplayTo: pages.pagesDisplayFrom - 1
        }))
    }
    if(type=='next'){
      if(pages.pagesDisplayTo == pages.numberOfPages.length) return ;
      setPages(prev=>({
        ...prev,
        pagesDisplayFrom: pages.pagesDisplayFrom + 5,
        pagesDisplayTo: pages.pagesDisplayTo + 5>pages.numberOfPages.length ? pages.numberOfPages.length : pages.pagesDisplayTo + 5
      }))
    }
  }

  const [categoriesOptionsArray,setCategoriesOptionsArray] = useState([]);
  const [selectedCategories,setSelectedCategories] = useState([]);
  const [selectedCategoryIds,setSelectedCategoryIds] = useState([]);
  const [searchCategoriesInput,setSearchCategoriesInput] = useState('');
  const categoriesSelectRef = useRef(null);
  const [showOptions,setShowOptions] = useState(false);

  useEffect(()=>{
      setCategoriesOptionsArray(categoriesOptions);
  },[categoriesOptions])

  const handleSearchCategory = (searchterm) => {
      setCategoriesOptionsArray(categoriesOptions.filter(el=> el.name.toLowerCase().includes(searchterm.toLowerCase())));
  }

  const removeSelectedCategory = (categoryId) => {
    setPageDetails(prev=>({
      ...prev,
      searchCategory: [...prev.searchCategory.filter(el=>el!=categoryId)]
    }));
      setSelectedCategories(prev=>([...prev.filter(el=>el.id!=categoryId)]));
    }

  const handleDropdown = () => {
      setShowOptions(prev=>!prev);
  }

  const handleCategoryOptionSelect = (category) => {
      if(selectedCategories && selectedCategories.find(el=>el == category)) return;
      setPageDetails(prev=>({
        ...prev,
        searchCategory: [...prev.searchCategory,category.id]
      }))
      setSelectedCategories(prev=>([...prev,category]));
  }

  const handleClickOutside = (e) => {
    if(categoriesSelectRef.current && !categoriesSelectRef.current.contains(e.target) && e.target.id!='search-category-input' && e.target.id!='dropdown-arrow'){
      setShowOptions(false);
      clearSearchFilter();
    }
  }

  const clearSearchFilter = () => {
    setSearchCategoriesInput('');
    setCategoriesOptionsArray(categoriesOptions);
  }

  return (
    <div className='document-container mx-4' onClick={(e)=>{handleClickOutside(e)}}>
      <div className='document-title py-2'>
        <div className='document-heading'>Documents</div>
        <div className='search-button ms-auto'>
        </div>
      </div>

      <div className='document-search'>
        <div className='search-title'>MetaData / Keywords</div>
        <div className='py-2 row w-100 mx-0'>
          <div className='col-xl-3 col-md-6 ps-0 pe-0 pe-md-3'>
            <div className='search-input-box w-100' ref={searchRef}>
              <input type='text' placeholder='Search Documents' className='form-control' id='search-documents' value={searchTerm} onChange={(e)=>{handleChange(e.target.value)}}/>
            </div>
          </div>
          <div className='col-xl-3 col-md-6 px-0 ps-md-3 pe-xl-3 mt-3 mt-md-0'>
            <div className={`categories-dropdown-container`} ref={categoriesSelectRef}>
              <div className='input-field'>
                <div className='search-category-container'>
                  <input type='text' className={`search-category-name`} placeholder='Select Categrories' id='search-category-input' value={searchCategoriesInput} onFocus={()=>{setShowOptions(true)}} onChange={(e) => { handleSearchCategory(e.target.value);setSearchCategoriesInput(e.target.value) }}/>
                  <i className="fa-solid fa-chevron-down cursor-pointer" id='dropdown-arrow' onClick={() => { handleDropdown();clearSearchFilter(); }}></i>
                </div>
                <div className='selected-categories-container'>
                  {selectedCategories && selectedCategories.map(category => {
                      return <span className='selected-category py-1'>{category.name} <i className="fa-solid fa-xmark px-1 small" onClick={() => { removeSelectedCategory(category.id) }}></i></span>
                  })}
                </div>
              </div>
              <div className='options-position-absolute'>
                <div className={`options-container  ${ showOptions ? '' : 'd-none'}` } >
                  {categoriesOptionsArray.length>0?categoriesOptionsArray.map(category => { return <li className={`category-option ${selectedCategories && selectedCategories.find(el => el.id == category.id) ? 'selected' : ''}`} onClick={() => { handleCategoryOptionSelect(category) }}>{category.name}</li>}):
                    <div className={``}>No Options Available</div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-md-6 ps-0 ps-xl-3 mt-3 mt-xl-0'>
            <div className='document-search-buttons'>
              <button className='btn btn-primary' onClick={()=>{resetDocumentList()}}>Reset</button>
            </div>
          </div>
        </div>
      </div>

      <DataTable toast={toast}/>

      <div className='document-footer pt-4'>
        <div className='page-numbers-section'>
          <div className={`previous-page px-1 mx-1 ${pages.pagesDisplayFrom==1?'disabled':''}`} onClick={()=>{changePagesDisplay('prev')}}><i className="fas fa-angle-double-left"></i></div>
          <div className={`previous px-1 ${pageDetails.page==1?'disabled':''}`} onClick={()=>{navigatePage(pageDetails.page-1)}}><i class="fas fa-angle-left"></i></div>
          <div className={`page-number ${pages.pagesDisplayFrom == 1?'d-none':'continuance-indicator'}`} disabled>...</div>
            {pages.numberOfPages.map((page,index)=>{
              return pages.pagesDisplayFrom-1<index+1 &&  pages.pagesDisplayTo > index && <div key={index+1} className={`page-number px-2 ${pageDetails.page == index+1?'active':''}`} onClick={()=>pageChange(index+1)}>{index + 1}</div>
              })}
            <div className={`page-number ${pages.pagesDisplayTo == pages.numberOfPages.length?'d-none':'continuance-indicator'}`} disabled>...</div>
            <div className={`next px-1 ${pageDetails.page==pages.numberOfPages.length?'disabled':''}`} onClick={()=>{navigatePage(pageDetails.page+1)}}><i class="fas fa-angle-right"></i></div>
            <div className={`next-page px-1 mx-1 ${pages.pagesDisplayTo==pages.numberOfPages.length?'disabled':''}`} onClick={()=>{changePagesDisplay('next')}}><i className="fas fa-angle-double-right"></i></div>
        </div>
        <div className='page-number-details grow-1'>{`Showing `}
          {documents?.length?(pageDetails.page-1)*pageDetails.perPageSize+1:0}{ ` to `} 
          {documents?.length?(pageDetails.page*pageDetails.perPageSize)>(pageInfo?.totalRecords)?(pageInfo?.totalRecords):(pageDetails.page*pageDetails.perPageSize):0}{` of `}
          {Object.keys(pageInfo).length?(pageInfo?.totalRecords):0} entries</div>
        <div className='number-of-pages'>
          Show <input type='text' className='entries-per-page px-1' value={perPageEntries} name='perPageSize' onBlur={(e)=>{updatePageDetails(e)}} onChange={(e)=>{updatePageEntries(e.target.value)}}/>
          entries
        </div>
      </div>
    </div>
  )
}

const mapStateToProps=(state)=>{
  return{
    documents : state.documents.documentList,
    pageInfo: state.documents.pageInfo,
    categoriesOptions: state.categories.categoriesOptions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAllFiles,
    getSearchResults,
    logout,
  },dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)(Documents)