import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

function CustomerManagementTable({customerList,categoriesOptions,toggleModal}) {
  const [customerData,setCustomerData] = useState();
  useEffect(()=>{
    const data = customerList.length>0 && customerList.map(customer=>{
      return {
        ...customer,
        configuredCategories: customer.configuredCategories.map(el=>categoriesOptions.filter(category=>category.id==el)[0])
      }
    });
    setCustomerData(data)
  },[customerList])
  return (
    <div className='document-table w-100'>
      <div className='table-section'>
          <table className='table-properties table-bordered'>
            <thead>
            <tr className='table-headers'>
                  <th className='py-3 px-2'>Customer Name</th>
                  <th className='py-3 px-2'>Key</th>
                  <th className='py-3 px-2'>Status</th>
                  <th className='py-3 px-2'>Configure Categories</th>
                  <th className='py-3 px-2 text-center'>Edit</th>
              </tr>
            </thead>
            <tbody>
              {customerData && customerData?.length && customerData.map(customer=>{
              return <tr key={customer.id} className='table-body-data'>
                    <td className='py-2 px-2 min-w-150'>{customer.name}</td>
                    <td className='py-2 px-2'>{customer.key}</td>
                    <td className='py-2 px-2'>{customer.status}</td>
                    <td className='py-2 px-2'>
                      {customer.configuredCategories.map(category=>category?.name).join(', ')}
                    </td>
                    <td className='py-2 px-2 text-center'>
                      <span onClick={()=>{toggleModal('Edit',customer)}}><img src='./images/pencil-alt-solid.svg' className='edit' alt='edit' /></span>
                    </td>
              </tr>
              })}
            </tbody>
          </table>
      </div>
    </div>
  )
}
const mapStateToProps = (state)=>{
  return {
    customerList: state.customers.customerList,
    categoriesOptions: state.categories.categoriesOptions
  }
}

const mapDispatchToProps = (dispatch)=>{
  return bindActionCreators({

  },dispatch)

}
export default connect(mapStateToProps,mapDispatchToProps)( CustomerManagementTable)