import axios from "axios";
import { ADD_LOADING, REMOVE_LOADING, SET_CATEGORIES_OPTIONS,SET_CATEGORIES_OPTIONS_PAGINATION } from './index';
const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

let controller;

export const getCategories = (data) => async(dispatch) => {
    setLoading(dispatch,'fetch-all-category',true);
    const accessToken = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')).idToken:'';
    let response;
    const url = `${BASE_API_URL}/db/category/fetchAllCategoriesForDropdown`;

    await axios({
        method:'GET',
        url:url,
        headers:{
            'Authorization': `Bearer ${accessToken}`
        },
        crossOrigin: true
    }).then(res=>{
    setLoading(dispatch,'fetch-all-category',false);
        dispatch({
            type: SET_CATEGORIES_OPTIONS,
            payload: res.data.data
        });
    }).catch(error=>{
    setLoading(dispatch,'fetch-all-category',false);

        if(!error){
            console.log('Network Error');
        }
        if(error && error?.response?.status==401){
            return response={
                status:'logout',
                message:'Session Expired'
            }
        }
        console.log(error);
        return response = {
            status:'error',
            message:'Something went wrong while fetching categories'
        }
    });

    return response;

}

export const getAllCategorieswithPagination = (pageDetails) => async(dispatch) => {
    setLoading(dispatch,'fetch-all-category-pagination',true);
    const accessToken = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')).idToken:'';
    let response;
    const url = `${BASE_API_URL}/db/category?page=${pageDetails.page}&size=${pageDetails.perPageSize}&sort=${pageDetails.sortColumn},${pageDetails.sortType}&searchString=${pageDetails.searchString}`;
    if(controller){
        controller.abort();
    }
    controller = new AbortController();
    await axios({
        method:'GET',
        url:url,
        headers:{
            'Authorization': `Bearer ${accessToken}`
        },
        signal: controller.signal,
        crossOrigin: true
    }).then(res=>{
    setLoading(dispatch,'fetch-all-category-pagination',false);
    const [sortColumn,sortType] = res.data.page.sort.substring( res.data.page.sort.indexOf("[") + 1, res.data.page.sort.lastIndexOf("]")).split(', ')
        const data = {
            categoriesList: res.data.data,
            pageInfo:{...res.data.page,page:res.data.page.currentPage,sortType:sortType,sortColumn:sortColumn,searchString:pageDetails.searchString}
        }
        
        dispatch({
            type: SET_CATEGORIES_OPTIONS_PAGINATION,
            payload: data
        });
    }).catch(error=>{
    setLoading(dispatch,'fetch-all-category-pagination',false);

        if(!error){
            console.log('Network Error');
        }
        if(error && error?.response?.status==401){
            return response={
                status:'logout',
                message:'Session Expired'
            }
        }
        console.log(error);
        return response = {
            status:'error',
            message:'Something went wrong while fetching categories'
        }
    });

    return response;

}


export const addNewCategory = (category) => async(dispatch) => {
    setLoading(dispatch,'add-new-category',true);
    const accessToken = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')).idToken:'';
    let response;
    const url = `${BASE_API_URL}/db/category`;

    const config = {
        method:'POST',
        url:url,
        headers:{
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type":"application/json",
            "access-control-allow-origin": "*",
            "Access-Control-Allow-Methods": "*",
        },
        data: JSON.stringify(category)
    }

    await axios(config).then(res=>{
        setLoading(dispatch,'add-new-category',false);
        if(res && res.status == 201){
            return response = {
                status:'success',
                message:'New Category Added successfully.'
            }
        }
    }).catch(error=>{
        setLoading(dispatch,'add-new-category',false);
        console.log('Error:',error);
        if(error && error?.response?.status == 400){
            return response = {
                status:'error',
                message: error.response.data.messages[0]
            }
        }
        if(error && error?.response?.status==401){
            return response = {
                status:'logout',
                message:'Session Expired'
            }
        }
    })
    return response;
}

export const editExistingCategory = (category) => async(dispatch) => {
    setLoading(dispatch,'edit-existing-category',true);
    const accessToken = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')).idToken:'';
    let response;
    const url = `${BASE_API_URL}/db/category`;

    const config = {
        method:'PUT',
        url:url,
        headers:{
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type":"application/json",
            "access-control-allow-origin": "*",
            "Access-Control-Allow-Methods": "*",
        },
        data: JSON.stringify(category)
    }

    await axios(config).then(res=>{
        setLoading(dispatch,'edit-existing-category',false);
        if(res && res.status == 200){
            return response = {
                status:'success',
                message:'Category updated successfully .'
            }
        }
    }).catch(error=>{
        setLoading(dispatch,'edit-existing-category',false);
        console.log('Error',error);
        if(error && error?.response?.status==401){
            return response = {
                status:'logout',
                message:'Session Expired'
            }
        }
        if(error && error?.response?.status == 400){
            return response = {
                status: 'error',
                message: error.response.data.messages[0]
            }
        }
        
    })
    return response;
}

export const deleteExistingCategory = (categoryIds) => async(dispatch) => {
    setLoading(dispatch,'delete-existing-category',true);

    const accessToken = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')).idToken:'';
    let response;
    const url = `${BASE_API_URL}/db/category`;

    const config = {
        method:'DELETE',
        url:url,
        headers:{
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type":"application/json",
            "access-control-allow-origin": "*",
            "Access-Control-Allow-Methods": "*",
        },
        data: categoryIds
    }

    await axios(config).then(res=>{
        setLoading(dispatch,'delete-existing-category',false);
        if(res && res.status == 200){
            const {associated,success,failed} = res.data.data;
            if(associated.length>0){
                return response = {
                    status:'warning',
                    message:'Category cannot be deleted as it is associated with other files.'
                }
            }
            if(success.length>0){
                return response = {
                    status:'success',
                    message:'Category deleted successfully .'
                }
            }
            if(failed.length>0){
                return response = {
                    status:'error',
                    message:'Failed to delete category.'
                }
            }
        }
    }).catch(error=>{
        setLoading(dispatch,'delete-existing-category',false);
        console.log('Error:',error);
        if(error && error?.response?.status==401){
            return response = {
                status:'logout',
                message:'Session Expired'
            }
        }
        
    })
    return response;
}



const setLoading = (dispatch, apiName, loadingState) => {
    loadingState ?
        dispatch({
            type: ADD_LOADING,
            payload: apiName
        }) :
        dispatch({
            type: REMOVE_LOADING,
            payload: apiName
        });
    }